import React, { useRef } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"

export default function BlogPost({ data }) {
  const post = data.allWpPost.nodes[0]
  const kategoriaIzenak = post.categories.nodes.map(k => k.name);
  const erdaraz = kategoriaIzenak.includes('Español');
  const hitzak = post.content.replace(/(&lt;([^>]+)>)/gi, "").split(' ').length; 
  return (
    <Layout>
      <Seo
        title={post.title}
        description={post.content.replace(/<[^>]*>?/gm, '').replace(/^(.{150}[^\s]*).*/, "$1")}
      />
      <div className={erdaraz && 'erdaraz'}>
        <h1 style={{
          fontSize: '2.3em',
          fontWeight: 700,
          marginBottom: '1em'
        }}>{post.title}</h1>
        <hr />
        <div className="categories" style={{textAlign: 'left'}}>
          <span>Kategoriak</span>
          {post.categories.nodes.map(kategoria => (
            <Link key={kategoria.slug} className="btn-giza" to={`/kategoria/${kategoria.slug}`}>{kategoria.name}</Link>
          ))}
        </div>
        <div className="edukia-post" dangerouslySetInnerHTML={{ __html: post.content }} />
        {!erdaraz && (
          <span className="text text-gray-700 font-bold">{hitzak} hitz</span>
        )}
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) { 
      nodes {
        title
        content
        categories {
          nodes {
            slug
            name
          }
        }
      }
    }
  }`